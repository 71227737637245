import { useEffect, useState } from "react";
import { Tabs } from "@mantine/core";
import "@mantine/core/styles.css";
import { getTechnologyData, getTechnologyOption } from "../apiHandler";

import { TbReload } from "react-icons/tb";
import { ourTechnologies, ourTechnologyBtns } from "../constants";

interface TechnologyData {
  _id: string;
  icon: string;
  title: string;
  category: string;
}

const OurTechnologies = () => {
  const [selectedTab, setSelectedTab] = useState("frontend");

  const [techOption, setTechOption] = useState<
    { _id: string; value: string }[]
  >([]);

  const [techData, setTechData] = useState<TechnologyData[]>([]);
  const [filteredTech, setFilteredTech] = useState<TechnologyData[]>([]);

  // const getTechnology = async () => {
  //   const response = await getTechnologyData();
  //   const response2 = await getTechnologyOption();
  //   setTechData(response);
  //   setTechOption(response2);

  //   const defData = Array.isArray(response)
  //     ? response.filter((data: any) => data.category === "frontend")
  //     : [];
  //   setFilteredTech(defData);
  // };

  // useEffect(() => {
  //   getTechnology();
  // }, []);

  const handleMouseEvent = (value: string) => {
    setSelectedTab(value);
    const filterData = techData.filter((data) => data.category === value);
    setFilteredTech(filterData);
  };

  // static data for now

  useEffect(() => {
    setTechData(ourTechnologies);
    setTechOption(ourTechnologyBtns);
    const filterData = ourTechnologies.filter(
      (data: any) => data.category === "frontend"
    );
    setFilteredTech(filterData);
  }, []);

  function handleReload() {
    // getTechnology()
  }
  const capitalizeFirstLetter = (str: string) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <div className="px-p83  bg-lightBg bg-cover">
      <p className="text-3xl 500:text-4xl pt-10">
        Our <span className="text-txtclr font-bold ">Technologies</span>
      </p>
      <p className="text-2xl 500:text-3xl font-light py-2.5">
        <i> Revolutionizing your business with innovative technologies</i>
      </p>
      <p className="text-base 500:text-lg">
        Designing and developing innovative and professional software solutions
        for hundred of companies across global tech marketers for almost a
        decade now.
      </p>
      <Tabs
        variant="pills"
        color="#0078D1"
        radius="xl"
        defaultValue={selectedTab}
        value={selectedTab}
      >
        <Tabs.List>
          <div className="pt-12 500:pt-20 pb-16 800:pb-32 gap-x-5 flex 1000:gap-x-10 1100:gap-x-12 1200:gap-x-14 1300:gap-x-16 1400:gap-x-20  gap-y-3 flex-wrap">
            {techOption.length > 0 ? (
              techOption.map(({ _id, value }) => (
                <Tabs.Tab
                  onMouseEnter={() => handleMouseEvent(value)}
                  key={_id}
                  value={value}
                  className="!px-5 500:!px-10 !py-2 !text-lg 500:!text-xl "
                  style={{
                    border: "2px solid #0078D1",
                  }}
                >
                  {capitalizeFirstLetter(value)}
                </Tabs.Tab>
              ))
            ) : (
              <button
                className="text-3xl absolute right-[50%] text-blue-600"
                onClick={() => handleReload()}
              >
                <TbReload />
              </button>
            )}
          </div>
        </Tabs.List>

        <Tabs.Panel value={selectedTab}>
          <div className="grid grid-cols-2 750:grid-cols-3 1000:grid-cols-4 1200:grid-cols-5 gap-y-3 gap-x-5 1400:gap-x-14 pb-14 800:pb-28">
            {filteredTech.length > 0 &&
              filteredTech.map(({ icon, title, _id }) => (
                <div
                  key={_id}
                  className="border-txtclr border-2 hover:border-[3px] hover:border-zinc-800 cursor-pointer mx-auto w-28 h-28 375:w-40 375:h-40  500:w-52 500:h-48 text-center flex justify-center items-center"
                >
                  <div>
                    <img
                      src={icon}
                      alt={title}
                      className="m-auto tech__img__responsive w-24"
                    />
                    <p className="font-bold text-lg 375:text-2xl pt-2 375:pt-5 ">
                      {title}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default OurTechnologies;
