import Process from "./subComponents/Process";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const OurServices = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="px-p83 bg-whiteBg bg-cover pb-12 700:pb-24" id="work">
      <h1 className="text-3xl 500:text-4xl font-semibold text-center pt-12 700:pt-24 pb-p30 ">
        Our <span className="text-txtclr font-bold"> Process</span>
      </h1>

      <div data-aos="fade-up" className="grid items-center grid-cols-4">
        <Process
          src={"/icons/planing-research.svg"}
          alt={"planning"}
          txt={"Planning & Research"}
        />
        <p className="text-3xl 400:text-4xl 600:text-5xl text-center font-semibold text-bodyText opacity-10 ">
          02
        </p>
        <Process
          src={"/icons/hand-thumbs-up.svg"}
          alt={"Feedback"}
          txt={"Stabilization & Feedback"}
        />
        <p className="text-3xl 400:text-4xl 600:text-5xl text-center font-semibold text-bodyText opacity-10 ">
          04
        </p>
      </div>

      <div className="relative">
        <p className="border text-center border-txtclr w-full absolute top-3.5" />
        <div className="flex justify-around relative  my-3.5 ">
          <div>
            <p className=" border rotate-90 border-txtclr relative " />
            <img
              src="icons/ecllipse.svg"
              alt="eclipse"
              className="relative z-50"
            />
          </div>
          <div>
            <img
              src="icons/ecllipse.svg"
              alt="eclipse"
              className="relative z-50"
            />
            <p className=" border rotate-90 border-txtclr relative " />
          </div>
          <div>
            <p className=" border rotate-90 border-txtclr relative " />
            <img
              src="icons/ecllipse.svg"
              alt="eclipse"
              className="relative z-50"
            />
          </div>
          <div>
            <img
              src="icons/ecllipse.svg"
              alt="eclipse"
              className="relative z-50"
            />
            <p className=" border rotate-90 border-txtclr relative " />
          </div>
        </div>
      </div>

      <div data-aos="fade-down" className="grid items-center grid-cols-4">
        <p className="text-3xl 400:text-4xl 600:text-5xl text-center font-semibold text-bodyText opacity-10 ">
          01
        </p>

        <Process
          src={"/icons/design-development.svg"}
          alt={"planning"}
          txt={"Design & Development"}
        />

        <p className="text-3xl 400:text-4xl 600:text-5xl text-center font-semibold text-bodyText opacity-10 ">
          03
        </p>
        <Process
          src={"/icons/maintenance.svg"}
          alt={"Feedback"}
          txt={"Maintenance & Support"}
        />
      </div>
    </div>
  );
};

export default OurServices;
