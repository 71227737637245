import { Tabs } from "@mantine/core";
import { useState } from "react";
import WebDevelopment from "./subComponents/WebDevelopment";
import MobileAppDevelopment from "./subComponents/MobileAppDevelopment";
import BackendDevelopment from "./subComponents/BackendDevelopment";
import UiUxDesign from "./subComponents/UiUxDesign";

const Services = () => {
  const [selectedTab, setSelectedTab] = useState("frontend");
  const data = [
    {
      value: "frontend",
      text: "Web Development",
    },
    {
      value: "mobile",
      text: "Mobile Application Development",
    },
    {
      value: "backend",
      text: "Game Development",
    },
    {
      value: "uiux",
      text: "UI/UX Design",
    },
  ];

  function handleMouseEvent(value: string) {
    setSelectedTab(value);
  }

  return (
    <div className="bg-serviceBg px-p83 bg-cover pb-20" id="services">
      <h1 className="text-[1.8rem] 500:text-4xl font-semibold text-center pt-10 500:pt-20 pb-8 500:pb-16">
        We’re Providing <br />
        <span className="font-bold text-txtclr "> High Quality Services </span>
      </h1>

      <div>
        <Tabs defaultValue="frontend" value={selectedTab}>
          <Tabs.List justify="space-between">
            {data.map(({ value, text }, index) => (
              <Tabs.Tab
                key={index}
                onMouseEnter={() => handleMouseEvent(value)}
                value={value}
                className="font-medium !text-lg  !text-wrap 500:!text-xl px-0 500:!px-5 hover:bg-transparent"
              >
                {text}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel value="frontend">
            <WebDevelopment mouse={handleMouseEvent} />
          </Tabs.Panel>

          <Tabs.Panel value="mobile">
            <MobileAppDevelopment />
          </Tabs.Panel>

          <Tabs.Panel value="backend">
            <BackendDevelopment />
          </Tabs.Panel>

          <Tabs.Panel value="uiux">
            <UiUxDesign />
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
};

export default Services;
