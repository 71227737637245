const UiUxDesign = () => {
  const data = [
    {
      heading: "Stupendous design delivery",
      content:
        "We believe and feel that the design process is everything that affects the user experience.",
    },
    {
      heading: "Attractive UI/UX",
      content:
        "We aim at establishing a remarkable business identity for the clients through our attractive UI/UX services.",
    },
    {
      heading: "Satisfying user experience",
      content:
        "We are known for providing exceptional experiences through our innovative designs.",
    },
  ];
  return (
    <div className="grid grid-cols-1  950:grid-cols-2 items-start gap-7 pt-12">
      <div className="relative">
        <div className="dotted-ring absolute left-[33%] top-[25.7%] 1400:left-[204px] 1400:top-[120px] w-[33.5%] h-[44.5%] 1400:w-[200px] 1400:h-[200px]"></div>

        <img
          src="/Services_Images/UiUx.svg"
          alt="Web Development"
          className="950:mx-0 width__resp mx-auto"
        />
      </div>
      <div>
        <h2 className="font-bold text-2xl 500:text-3xl pb-2">UI/UX Design</h2>
        <p className="text-bodyText 500:text-base pb-10">
          UI/UX is the heart of any application. Our developers are well-versed
          in this trend, and as a result, we create interactive and
          collaborative prototypes for each application. We accomplish a more
          personalized, result-oriented, user-centric interface that provides
          the most interactive user experience feasible.
        </p>
        <div className="grid 300:grid-cols-2 500:grid-cols-3 gap-4 ">
          {data.map(({ heading, content }, i) => (
            <div key={i} className="flex items-start gap-4 max-w-60">
              <img
                src="icons/rightTick.svg"
                alt="Right Tick"
                className="mt-1.5"
              />
              <div>
                <p className="font-medium text-lg">{heading}</p>
                <p className="text-bodyText font-medium">{content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UiUxDesign;
