interface feedback {
  id?: number;
  image: string;
  username: string;
  position: string;
  content: string;
  company: string;
}

const FeedbackCard = ({
  image,
  username,
  position,
  content,
  company,
}: feedback) => {
  return (
    <div className="bg-lightBg w-[250px] h-[400px] 375:w-[335px] overflow-y-auto 375:h-[450px] rounded-xl p-5 relative scrollbar-track-txtclr scrollbar-track-rounded-xl  scrollbar-w-1 scrollbar scrollbar-h-auto feedback__scrollbar">
      <div className="flex items-center gap-2 py-5">
        <p className="font-semibold text-lg">Nexasoft</p>
        <p className="w-10 h-1 bg-[#085996] rounded-xl" />
      </div>

      <div className="pt-7">
        <img
          src={image}
          alt={username}
          className="border-2 border-txtclr rounded-full w-10 h-10 object-cover"
        />
        <p className="pt-3 font-semibold text-base">{username}</p>
        <p>{company + ", " + position}</p>
        <div className="flex items-end mb-5">
          <p className="pt-6 text-bodyText text-base">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCard;
