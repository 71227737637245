const BackendDevelopment = () => {
  const data = [
    {
      heading: "Creative",
      content:
        "We turn a creative gaming idea into a fantabulously great game to be in the trend.",
    },
    {
      heading: "Dynamic",
      content:
        "We've been known over the years for creating games that are dynamically elegant.",
    },
    {
      heading: "Smooth gaming experience",
      content:
        "We offer our users a unique gaming experience to keep them interested.",
    },
  ];
  return (
    <div className="grid grid-cols-1  950:grid-cols-2 items-start gap-7 pt-12">
      <div className="relative">
        <div className="dotted-ring absolute left-[33%] top-[25.7%] 1400:left-[204px] 1400:top-[120px] w-[33.5%] h-[44.5%] 1400:w-[200px] 1400:h-[200px]"></div>

        <img
          src="/Services_Images/GameDev.svg"
          alt="Web Development"
          className="950:mx-0 width__resp mx-auto"
        />
      </div>
      <div>
        <h2 className="font-bold text-2xl 500:text-3xl pb-2">
          Game Development
        </h2>
        <p className="text-bodyText 500:text-base pb-10">
          Nexasoft is an acclaimed game development station with highly talented
          Unity game developers. Our Mobile Game Developers are very competent
          in offering mobile game technology for Android and iOS platforms, with
          over a decade of rich experience. Nexasoft is a trailblazer in every
          level of game development and is ranked among the best mobile gaming
          businesses abroad.
        </p>
        <div className="grid 300:grid-cols-2 500:grid-cols-3 gap-4 ">
          {data.map(({ heading, content }, i) => (
            <div key={i} className="flex items-start gap-4 max-w-60">
              <img
                src="icons/rightTick.svg"
                alt="Right Tick"
                className="mt-1.5"
              />
              <div>
                <p className="font-medium text-lg">{heading}</p>
                <p className="text-bodyText font-medium">{content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BackendDevelopment;
