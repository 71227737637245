import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

interface mouse {
  mouse: (value: string) => void;
}
const WebDevelopment = ({ mouse }: mouse) => {
  const data = [
    {
      heading: "Futuristic",
      content:
        "As Web app usage has grown over the years, we've created unique website that play a dominant role in the Web industry.",
    },
    {
      heading: "Unique",
      content:
        "Before we start designing, we study the website design of your competitors so that we can create an aesthetic UI for the web.",
    },
    {
      heading: "Support services",
      content:
        "Our dedicated and talented support team is at your behest. We offer a full range of support services in no time.",
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="grid grid-cols-1 950:grid-cols-2 items-start gap-7 pt-12">
      <div className="relative">
        <div className="dotted-ring absolute left-[33%] top-[25.7%] 1400:left-[204px] 1400:top-[120px] w-[33.5%] h-[44.5%] 1400:w-[200px] 1400:h-[200px]"></div>

        <img
          src="/Services_Images/WebDev.svg"
          alt="Web Development"
          className="950:mx-0 width__resp mx-auto"
        />
      </div>
      <div>
        <h2 className="font-bold text-2xl 500:text-3xl pb-2">
          Web Development
        </h2>
        <p className="text-bodyText 500:text-base pb-10">
          As a leading Web development company, we design website in languages
          such as React.js, Node.js, JavaScript, Next.js and WordPress to shape
          Website ideas into reality. We’ve launched some of the most successful
          website with responsive technology to hit the Web.
        </p>
        <div className="grid 400:grid-cols-2 500:grid-cols-3 gap-4 ">
          {data.map(({ heading, content }, i) => (
            <div key={i} className="flex items-start gap-4 max-w-60">
              <img
                src="icons/rightTick.svg"
                alt="Right Tick"
                className="mt-1.5"
              />
              <div>
                <p className="font-medium text-lg">{heading}</p>
                <p className="text-bodyText font-medium">{content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;
