import { useState } from "react";
import { navbar } from "../constants";
import { IoMdMenu } from "react-icons/io";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <div className=" items-center justify-between pt-7 flex px-p83 ">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => (window.location.href = "/")}
        >
          <img src="/logo.png" alt="LOGO" className={`w-10 500:w-12`} />
          <span className={`text-3xl 500:text-4xl font-semibold`}>
            {" "}
            NEXASOFT{" "}
          </span>
        </div>
        {/* desktop menu */}
        <nav className=" hidden 1100:flex gap-6 items-center  ">
          {navbar.map(({ menu, id, href }) => {
            return (
              <a
                href={href}
                key={id}
                className="font-medium hover:text-txtclr transition-colors duration-200"
              >
                {menu}
              </a>
            );
          })}
          <a
            href="#contact"
            className="text-[16px] toBlue font-semibold border text-center py-p10 px-p30 rounded-lg border-[#17171780] hover-bg"
          >
            Contact
          </a>
        </nav>
        <IoMdMenu
          className="text-3xl 1100:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        {/* Mobile menu */}
        {isMenuOpen && (
          <nav className="grid absolute top-20  py-5   bg-white  w-full left-0 justify-center z-[1000] gap-6 items-center 1100:hidden">
            {navbar.map(({ menu, id, href }) => {
              return (
                <a
                  href={href}
                  key={id}
                  className="text-sm font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {menu}
                </a>
              );
            })}
            <a
              href="#contact"
              onClick={() => setIsMenuOpen(false)}
              className="text-[16px] toBlue font-semibold border text-center py-p10 px-p30 rounded-lg border-[#17171780] hover-bg  "
            >
              Contact
            </a>
          </nav>
        )}
      </div>
    </>
  );
};
export default Header;
