import ContactForm from "./subComponents/ContactForm";

const contactData = [
  {
    id: 1,
    icon: "/icons/email.svg",
    title: "Email Us",
    link: "nexasoft.infotech@gmail.com",
  },
  {
    id: 2,
    icon: "/icons/hiring.svg",
    title: "Hiring Now",
    link: "nexasoft.infotech@gmail.com",
  },
  {
    id: 3,
    icon: "/icons/visit.svg",
    title: "Visit Us",
    href: "21.237335, 72.909297",
    link: "413, skyzone business hub, near shyamdham mandir, jakatnaka, Surat - 395006",
  },
];

const ContactUs = () => {
  return (
    <div
      className="bg-contactBg pb-10 pr-p83 pt-16 gap-10 flex flex-col-reverse 800:grid 800:grid-cols-2  justify-center px-p83 800:pl-0 items-center"
      id="contact"
    >
      <div className="bg-white pl-p83 py-14 pr-5 320:pr-14 relative max-w-[450px] ">
        <p className="text-4xl font-semibold">Info</p>
        <div>
          {contactData.map(({ id, icon, title, link, href }) => (
            <a
              href={`${
                title !== "Visit Us"
                  ? `mailto: ${link}`
                  : `https://maps.google.com/maps?q=${encodeURIComponent(
                      href || ""
                    )}&navigate=yes`
              }`}
              key={id}
              target="_blank"
              className="flex items-start text-wrap gap-2 px-3 350:gap-5 pt-7"
            >
              <img src={icon} alt={title} className="mt-2" />
              <span>
                <p className="text-lg font-semibold">{title}</p>
                <p className="text-bodyText text-wrap">{link}</p>
              </span>
            </a>
          ))}
        </div>
        <p className="bg-txtclr 375:w-[55px] 375:h-[55px] absolute -top-7 -right-7 mr-2 " />
      </div>

      <div>
        <h3 className="text-3xl 500:text-4xl font-semibold ">
          Contact <span className="font-bold text-txtclr"> Us </span>
        </h3>
        <p className="500:text-base text-sm text-bodyText pt-2">
          Feel free to contact us any time. we will get back to you as soon as
          we can!
        </p>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactUs;
