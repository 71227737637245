import { useRef, useState } from "react";
import { ValidateForm, validateEmail } from "../../utils";
import { FormData } from "../../interfaces";
import { sendContactFormData } from "../../apiHandler";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const defaultFormData = {
    username: "",
    phone: "",
    email: "",
    budget: "",
    howDidYouHear: "",
    startTime: "",
    message: "",
  };
  const [formData, setFormData] = useState<FormData>(defaultFormData);

  const [isOption1Open, setIsOption1Open] = useState(false);
  const [isOption2Open, setIsOption2Open] = useState(false);
  const [animate, setAnimate] = useState(false);

  const inputStyle =
    "outline-none border-none focus:border focus:outline-txtclr rounded-md transition-all duration-700 focus:placeholder:text-sm validateForm focus:placeholder:transition-all focus:placeholder:duration-500 w-full caret-txtclr py-2.5 px-2 320:px-5 placeholder:px-1 ";

  const handleInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (ValidateForm(formData)) {
      if (validateEmail(formData.email)) {
        try {
          // const response = await sendContactFormData(formData);
          // toast(response.message);
          sendEmail(e);
          setFormData(defaultFormData);
        } catch (err) {
          toast.error(`there is some error please try again later`);
        }
      } else {
        toast.error("email is not valid");
      }
    } else {
      toast.error("please fill all details");
    }
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  };
  const form = useRef<any>(null);

  const sendEmail = (e: any) => {
    e.preventDefault();
    const SERVICE_KEY = "service_99hiczf";
    const PUBLIC_KEY = "Q9b_snzRQTN318hjZ";
    const TEMPLATE_ID = "template_kzm17wr";

    emailjs.sendForm(SERVICE_KEY, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result: any) => {
        console.log(result.text);
      },
      (error: any) => {
        console.log(error.text);
      }
    );

    toast.success("message sent succesfully");
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleFormSubmit}
      method="post"
      ref={form}
    >
      <Toaster />
      <div className=" gap-5 py-7 flex flex-col w-full ">
        <div className="flex gap-5">
          <input
            type="text"
            className={`${inputStyle} ${
              formData.username === "" && animate ? "animateForm" : ""
            }`}
            placeholder="Name"
            name="username"
            onChange={handleInput}
            value={formData.username}
          />
          <input
            type="number"
            className={`${inputStyle} ${
              formData.phone === "" && animate ? "animateForm" : ""
            }`}
            placeholder="Phone Number"
            name="phone"
            onChange={handleInput}
            value={formData.phone}
          />
        </div>
        <div className="flex gap-5">
          <input
            type="text"
            className={`${inputStyle} ${
              formData.email === "" && animate ? "animateForm" : ""
            } ${
              !validateEmail(formData.email) && animate ? "animateForm" : ""
            }`}
            placeholder="Email Address"
            name="email"
            onChange={handleInput}
            value={formData.email}
          />
          <input
            type="number"
            className={`${inputStyle} ${
              formData.budget === "" && animate ? "animateForm" : ""
            }`}
            placeholder="Your Budget"
            name="budget"
            onChange={handleInput}
            value={formData.budget}
          />
        </div>
        <div className="grid 1100:flex gap-5">
          <span className="relative w-full">
            <select
              name="startTime"
              onClick={() => setIsOption1Open(!isOption1Open)}
              onBlur={() => setIsOption1Open(false)}
              className={`${inputStyle} appearance-none  `}
              onChange={handleInput}
              value={formData.startTime}
            >
              <option value="" disabled defaultValue={""} hidden>
                When do you want to start
              </option>
              <option value="Start in a month">Start in a month</option>
              <option value="Engage immediately">Engage immediately</option>
              <option value="Start in a week">Start in a week</option>
            </select>
            {!isOption1Open ? (
              <img
                src="/icons/downArrow.svg"
                className="absolute top-3 right-0"
                alt="Down"
              />
            ) : (
              <img
                src="/icons/upArrow.svg"
                className="absolute top-3 right-0"
                alt="Down"
              />
            )}
          </span>
          <span className="relative w-full">
            <select
              name="howDidYouHear"
              onClick={() => setIsOption2Open(!isOption2Open)}
              onBlur={() => setIsOption2Open(false)}
              className={`${inputStyle} appearance-none`}
              onChange={handleInput}
              value={formData.howDidYouHear}
            >
              <option value="" disabled defaultValue={""} hidden>
                How did you hear about us*
              </option>
              <option value="Referral">Referral</option>
              <option value="Google/ Search">Google/ Search</option>
              <option value="Clutch">Clutch</option>
              <option value="Awards">Awards</option>
              <option value="Social Media">Social Media</option>
              <option value="Dribbble/ Behance">Dribbble/ Behance</option>
            </select>
            {!isOption2Open ? (
              <img
                src="/icons/downArrow.svg"
                className="absolute top-3 right-0"
                alt="Down"
              />
            ) : (
              <img
                src="/icons/upArrow.svg"
                className="absolute top-3 right-0"
                alt="Down"
              />
            )}
          </span>
        </div>
      </div>
      <textarea
        name="message"
        cols={30}
        rows={5}
        placeholder="Message..."
        className={`${inputStyle} resize-none ${
          formData.message === "" && animate ? "animateForm" : ""
        }`}
        onChange={handleInput}
        value={formData.message}
      ></textarea>

      <p className="text-xs text-bodyText pt-3.5">
        By proceeding, you are agreeing to Nexasoft Privacy Policy &
        communication
      </p>

      <button
        type="submit"
        className="w-full blueToYellow text-white text-base py-2.5 font-semibold  rounded-lg mt-p30 "
      >
        Send Enquiry{" "}
      </button>
    </form>
  );
};

export default ContactForm;
