import Blogs from "./Blogs";
import ContactUs from "./ContactUs";
import Feedback from "./Feedback";
import Footer from "./Footer";
import Header from "./Header";
import HeroSection from "./HeroSection";
import HowWeDifferent from "./HowWeDifferent";
import OurStory from "./OurStory";
import OurTechnologies from "./OurTechnologies";
import Services from "./Services";
import OurProcess from "./OurProcess";

const Home = () => {
  return (
    <div className="bg-lightBg bg-cover ">
      <Header />
      <HeroSection />
      <HowWeDifferent />
      <OurTechnologies />
      <OurProcess />
      <Services />
      <Feedback />
      <OurStory />
      <Blogs />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
