import { useEffect, useState } from "react";
import { getBlogData } from "../apiHandler";
import { blogDataTypes } from "../interfaces";
import { blogsData } from "../constants";

const Blogs = () => {
  // const [data, setData] = useState<Array<blogDataTypes>>([]);
  const [data, setData] = useState(blogsData);

  // const getData = async () => {
  //   const response = await getBlogData();
  //  setData(response);
  // };
  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <div className="bg-white px-p83 pb-10 " id="blogs">
      <div className="flex justify-between pt-10 500:pt-20">
        <h2 className="text-2xl 350:text-3xl 600:text-4xl font-semibold">
          Our Latest <span className="text-txtclr font-bold"> Blogs </span>{" "}
        </h2>
        <button className="border rounded-lg px-3 400:px-p30 py-2 font-semibold toBlue text-xs 375:text-base border-black">
          View All
        </button>
      </div>

      <div>
        <div className="flex overflow-x-auto gap-5 pt-10 scrollbar-none">
          {data.length > 0 ? (
            data.map(({ _id, image, title, description, tags }) => (
              <div
                key={_id}
                className="max-w-60 sm:max-w-72 md:max-w-96 overflow-x-auto cursor-pointer shrink-0"
                onClick={() => {
                  window.open(`/blog/${_id}`, "_blank", "noopener,noreferrer");
                }}
              >
                <img
                  src={image}
                  alt="Blog Post"
                  className="rounded-xl h-44 sm:h-52 md:h-60 max-w-full object-fill"
                />
                <div className="py-2">
                  <p className="text-lg 400:text-2xl font-semibold line-clamp-2 min-h-16">
                    {title}
                  </p>
                  <p
                    className="line-clamp-4 max-sm:text-sm py-2 text-bodyText"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <span className="flex gap-x-p10 flex-wrap gap-y-2">
                    {tags.map((data, i) => (
                      <p
                        key={i}
                        className="px-p10 py-1 bg-[#ffa43833] rounded-lg md:text-base text-sm"
                      >
                        {data}
                      </p>
                    ))}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p className="font-semibold text-center w-full py-2 text-xl">
              No Data Available
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
