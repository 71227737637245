const MobileAppDevelopment = () => {
  const data = [
    {
      heading: "Easy customization",
      content:
        "For us, the android app is a popular choice because it offers multiple customization features to developers.",
    },
    {
      heading: "Enhanced security",
      content:
        "Android offers sleek in-built security features that provide security and reliability to users.",
    },
    {
      heading: "Versatility",
      content:
        "Nowadays mobile application field is blooming and android is a versatile platform with higher flexibility.",
    },
  ];

  return (
    <div className="grid grid-cols-1  950:grid-cols-2 items-start gap-7 pt-12">
      <div className="relative">
        <div className="dotted-ring absolute left-[33%] top-[25.7%] 1400:left-[204px] 1400:top-[120px] w-[33.5%] h-[44.5%] 1400:w-[200px] 1400:h-[200px]"></div>

        <img
          src="/Services_Images/AppDev.svg"
          alt="Web Development"
          className="950:mx-0 width__resp mx-auto"
        />
      </div>
      <div>
        <h2 className="font-bold text-2xl 500:text-3xl pb-2">
          Mobile App Development
        </h2>
        <p className="text-bodyText 500:text-base pb-10">
          With more than a decade of expertise, Nexasoft offers you a peerless
          edge when it comes to Android apps development. We understand galore
          the ins and outs of app development intricacies and create android
          apps that are resilient, potent, and adaptable. <br /> Our expert
          Android app developers created numerous successful apps that
          efficiently work on the latest versions of Android technologies.
        </p>
        <div className="grid 300:grid-cols-2 500:grid-cols-3 gap-4 ">
          {data.map(({ heading, content }, i) => (
            <div key={i} className="flex items-start gap-4 max-w-60">
              <img
                src="icons/rightTick.svg"
                alt="Right Tick"
                className="mt-1.5"
              />
              <div>
                <p className="font-medium text-lg">{heading}</p>
                <p className="text-bodyText font-medium">{content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileAppDevelopment;
