export const ValidateForm = (data: any): boolean => {
  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key] === "") {
      return false;
    }
  }
  return true;
};
export const validateEmail = (input: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(input);
};

