import { useEffect, useRef, useState } from "react";
import FeedbackCard from "./subComponents/FeedbackCard";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import { getFeedbackData } from "../apiHandler";
import { feedback } from "../interfaces";
import { clientData } from "../constants";

const Feedback = () => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const [data, setData] = useState<Array<feedback>>([]);
  function handleLeftScroll(ref: React.RefObject<HTMLDivElement>) {
    const scrollAmount = window.innerWidth <= 375 ? 270 : 340;
    ref.current!.scrollLeft -= scrollAmount;
  }

  function handleRightScroll(ref: React.RefObject<HTMLDivElement>) {
    const scrollAmount = window.innerWidth <= 375 ? 270 : 340;
    ref.current!.scrollLeft += scrollAmount;
  }

  // Fetch Data from API

  // const getData = async () => {
  //   const response = await getFeedbackData();
  //   setData(response);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  // Static Data

  useEffect(() => {
    setData(clientData);
  }, []);

  return (
    <div
      className="bg-feedbackBg bg-cover px-p83 pb-12 500:pb-24 select-none "
      id="testimonials"
    >
      <h2 className="font-semibold text-3xl 500:text-4xl text-center pt-12 500:pt-24 pb-10">
        Feedback From <br />
        <span className="font-bold text-txtclr"> Our Client </span>
      </h2>
      <div className="relative">
        {data.length > 3 && (
          <button
            onClick={() => handleRightScroll(scrollContainer)}
            className={`absolute z-10 right-0 text-4xl top-[50%] text-txtclr`}
          >
            <IoIosArrowDropright />
          </button>
        )}
        <div
          className="flex gap-5 relative overflow-x-auto scrollbar-none "
          ref={scrollContainer}
        >
          {data.length > 0 ? (
            data.map(({ _id, Name, company, description, image, position }) => (
              <div key={_id}>
                <FeedbackCard
                  image={image}
                  username={Name}
                  company={company}
                  position={position}
                  content={description}
                />
              </div>
            ))
          ) : (
            <p className="font-semibold text-center w-full py-2 text-xl">
              No Data Available
            </p>
          )}
        </div>
        {data.length > 3 && (
          <button
            onClick={() => handleLeftScroll(scrollContainer)}
            className={`absolute z-10 left-0 top-[50%] text-4xl text-txtclr`}
          >
            <IoIosArrowDropleft />
          </button>
        )}
      </div>
    </div>
  );
};

export default Feedback;
