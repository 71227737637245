import { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";

const HeroSection = () => {
  const TEXTS = [
    "Web Application",
    "Mobile Application ",
    "Automation",
    "Softwares",
  ];
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 2000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="pt-20 950:pt-40 pl-p83 pb-24 950:grid 950:grid-cols-2 flex flex-wrap-reverse gap-10 950:justify-between">
      <div className="px-0.5">
        <div className="text-[26px] 325:text-3xl 500:text-4xl transition-all duration-300 700:text-5xl font-semibold">
          <div
            className="grid gap-x-0 550:flex 550:gap-x-2 950:grid 950:gap-x-0 1400:flex 1400:gap-2"
            id="clients"
          >
            Building
            <span className="text-txtclr font-bold text-2xl 280:text-[26px]  325:text-3xl 500:text-4xl 700:text-5xl">
              <TextTransition springConfig={presets.wobbly}>
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
              <p className="max-w-72 h-1.5 bg-txtclr bg-opacity-25"></p>
            </span>
          </div>
          That Drives Company Success
        </div>
        <div>
          <button
            onClick={() => (window.location.href = "#contact")}
            className="px-p30 blueToYellow py-p10 rounded-lg font-semibold text-base bg-btnBgDark text-white mt-5 700:mt-16"
          >
            Get A Free Consult
          </button>
        </div>
      </div>

      <div className="flex-col flex ">
        <div className="relative">
          <div className="flex w-full justify-end">
            <img
              src="/Mockup.svg"
              alt="Macbook"
              className="z-50 relative pr-7 width__resp"
            />
          </div>
          <img
            src="Background_Images/device_bg.svg"
            alt="Device"
            className="absolute bottom-0 right-0 width__resp"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
